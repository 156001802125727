import React, { ReactNode } from 'react';
import { useParallax } from 'react-scroll-parallax';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

type Props = {
  children: ReactNode;
  image: string;
  speed: number;
  position: string;
};

const ParalaxComponent: React.FC<Props> = ({
  children,
  image,
  speed,
  position,
}) => {
  const parallax = useParallax<HTMLDivElement>({
    easing: 'easeOutQuad',
    speed: 5,
  });

  const imageStyle = {
    height: '460px',
    width: '204px',
    margin: '0 auto',
  };

  const container = {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: position,
  };

  return (
    <div ref={parallax.ref}>
      <ParallaxBanner style={imageStyle}>
        <ParallaxBannerLayer style={container} image={image} speed={speed} />
      </ParallaxBanner>
      {children}
    </div>
  );
};

export default ParalaxComponent;
