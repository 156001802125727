import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import { ReactNode, useEffect, useId, useMemo } from 'react';
import {
  importImage,
  importVideo,
  redirect,
  setMobileScroll,
} from '@/helper/functions';
import { isMobile, isTablet } from 'react-device-detect';
import useWindowSize from '@/hooks/useWindowSize';

import ParalaxComponent from '@/components/UI/parallax';

import category from '@/assets/icons/category.svg';
import news from '@/assets/icons/News.svg';
import review from '@/assets/icons/review.svg';

import style from '../../app.module.scss';
import { useRouter } from 'next/router';

const Card = dynamic(() => import('@/components/UI/card'));
const IntroVideoLogo = dynamic(() => import('@/components/introVideoWithLogo'));
const VideoBlockWithInfo = dynamic(
  () => import('@/components/videoBlockWithInfo')
);
const Customization = dynamic(() => import('@/components/customization'));
const OnlineMenu = dynamic(() => import('@/components/onlineMenu'));
const Partners = dynamic(() => import('@/components/partners'));
const TrafficPlan = dynamic(() => import('@/components/trafficPlan'));
const Footer = dynamic(() => import('@/components/footer'));
const Fullpage = dynamic(() => import('@/components/fullPage'));

const MainContent = () => {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { windowWidth } = useWindowSize();
  const id = useId();

  useEffect(() => {
    const tenantID = sessionStorage.getItem('tenantID');

    if (tenantID) {
      redirect(router, '/subscribe');
    }
  }, [router]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 0) {
        localStorage.setItem(
          'mobileScroll',
          JSON.stringify(document.documentElement.scrollTop)
        );
      }
    });
  });

  useEffect(() => {
    setMobileScroll();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderParalax = (
    component: ReactNode,
    nameImage: string,
    speed: number,
    position: string
  ) => {
    return (
      <ParalaxComponent
        image={importImage(nameImage, i18n.language).src}
        speed={speed}
        position={position}
      >
        {component}
      </ParalaxComponent>
    );
  };

  const renderOtherComponents = () => {
    return (
      <>
        <Customization />
        <OnlineMenu />
        <Partners />
        <TrafficPlan />
        <Footer />
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderReviewBlock = () => {
    return (
      <VideoBlockWithInfo
        title={t('videoBlock.reviewTitle')}
        describe={t('videoBlock.reviewDescribe')}
        buttonText={t('button.myself')}
        cards={[
          <Card
            icon={review}
            alt='review'
            text={t('videoBlock.reviewCard')}
            otherStyleImage={style.rewiev}
            otherStyleText={style.text_rewiev}
            otherStyle={style.reviewText}
            key={id}
          />,
        ]}
        content={importVideo('review', i18n.language)}
        reverse={false}
      />
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderCategoryBlock = () => {
    return (
      <VideoBlockWithInfo
        title={t('videoBlock.qrTitle')}
        describe={t('videoBlock.qrDescribe')}
        buttonText={t('button.myself')}
        cards={[
          <Card
            icon={category}
            alt='category'
            text=''
            otherStyleImage={style.qr}
            key={id}
          />,
          <Card
            icon=''
            alt='category'
            text={t('videoBlock.qrCard')}
            otherStyle={style.qrText}
            key={id}
          />,
        ]}
        content={importVideo('QR-menu', i18n.language)}
        reverse={false}
      />
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderNewsBlock = () => {
    return (
      <VideoBlockWithInfo
        title={t('videoBlock.actionTitle')}
        describe={t('videoBlock.actionDescribe')}
        buttonText={t('button.want')}
        cards={[
          <Card
            icon={news}
            alt='news'
            text={t('videoBlock.actionCard')}
            otherStyleImage={style.news}
            otherStyle={style.newsText}
            otherStyleText={style.news_text}
            key={id}
          />,
        ]}
        content={importVideo('actions', i18n.language)}
        reverse={true}
      />
    );
  };

  const renderContent = useMemo(() => {
    if (windowWidth <= 720) {
      return (
        <>
          <IntroVideoLogo
            text={t('introVideo.text')}
            buttonText={t('button.upgrate')}
          />
          {renderParalax(renderCategoryBlock(), 'first', -15, '65%')}
          {renderParalax(renderNewsBlock(), 'second', -20, 'center')}
          {renderParalax(renderReviewBlock(), 'third', -20, 'center')}
          {renderOtherComponents()}
        </>
      );
    } else if (windowWidth > 720 && isTablet) {
      return (
        <>
          <Fullpage speed={1000}>
            <>
              <div className={style.item} data-item='item'>
                <IntroVideoLogo
                  text={t('introVideo.text')}
                  buttonText={t('button.upgrate')}
                />
              </div>
              <div className={style.item} data-item='item'>
                {renderCategoryBlock()}
              </div>
              <div className={style.item} data-item='item'>
                {renderNewsBlock()}
              </div>
              <div className={style.item} data-item='item'>
                {renderReviewBlock()}
              </div>
              <div className={style.other} data-item='item'>
                {renderOtherComponents()}
              </div>
            </>
          </Fullpage>
        </>
      );
    } else if (windowWidth > 720 && isMobile) {
      return (
        <>
          <IntroVideoLogo
            text={t('introVideo.text')}
            buttonText={t('button.upgrate')}
          />
          {renderNewsBlock()}
          {renderReviewBlock()}
          {renderOtherComponents()}
        </>
      );
    }

    return (
      <>
        <Fullpage speed={1000}>
          <>
            <div className={style.item} data-item='item'>
              <IntroVideoLogo
                text={t('introVideo.text')}
                buttonText={t('button.upgrate')}
              />
            </div>
            <div className={style.item} data-item='item'>
              {renderCategoryBlock()}
            </div>
            <div className={style.item} data-item='item'>
              {renderNewsBlock()}
            </div>
            <div className={style.item} data-item='item'>
              {renderReviewBlock()}
            </div>
            <div className={style.other} data-item='item'>
              {renderOtherComponents()}
            </div>
          </>
        </Fullpage>
      </>
    );
  }, [
    renderCategoryBlock,
    renderNewsBlock,
    renderParalax,
    renderReviewBlock,
    t,
    windowWidth,
  ]);

  return <>{renderContent}</>;
};

export default MainContent;
